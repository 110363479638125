@font-face {
    font-family: 'Montserrat';
    src: url('./fonts/Montserrat-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrati';
    src: url('./fonts/Montserrat-Medium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

h1, h2 {
    cursor: default;
}

.Login {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-family: 'Montserrat', sans-serif;
}

.background-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-image: url('./images/background.jpg');
    background-size: cover;
    background-position: center;
    filter: blur(2px);
    z-index: -1; /* Поместить фон под контент */
    background-color: #090c1b;
    display: flex;
    align-items: center;
    justify-content: center;
}

.background-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6); /* Черный фон с 50% прозрачностью */
    z-index: -1; /* Убедитесь, что затемняющий слой находится ниже всех других элементов внутри контейнера */
}

.auth-field {
    height: 300px;
    width: 450px;
    background-color: #282937;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 20px 7px rgb(0, 0, 0);
    padding: 10px 17px 25px 17px;

}

.login-fields {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background-color: #f1f1f1;
    border-radius: 15px;
    padding: 10px;
}

.login-header {
    display: flex;
    width: 100%;
    height: 65px;
    justify-content: left;
    align-items: center;
    padding-left: 10px;
    background-color: #282937;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    flex-shrink: 0;
    /*box-shadow: 0px 0px 5px 0px rgb(30, 30, 30);*/
}

.login-fields-input, .login-fields-button {
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    border-radius: 5px;
    border: none;
    height: 30px;
    padding: 5px;
    display: flex;
}

.login-fields-input {
    width: 60%;
    margin: 5px;
    box-shadow: inset 0px 0px 4px 0px rgba(30, 30, 30, 0.25);
    padding-left: 10px;
}

.login-fields-input::placeholder {
    color: rgba(0, 0, 0, 0.15); /* Замени #desiredColor на желаемый цвет */
}

.login-fields input[type="text"]:focus {
    outline: none;
}

.login-fields input[type="password"]:focus {
    outline: none;
}

.login-fields-button {
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
}

.login-fields-button {
    margin-top: 20px;
    padding: 0px 4px 0px 4px;
    width: 100px;
    cursor: pointer;
    background-color: #54adff;
    border: 0px;
    box-shadow: 0px 0px 5px 0px rgb(0, 0, 0, 0.3);
    border-radius: 5px;
    font-family: 'Montserrati', sans-serif;
    font-size: 14px;
    transition: background-color 0.2s ease;
    outline: none;
}

.login-fields-button:hover {
    background-color: #007bff;
}

.login-fields-button:active {
    background-color: #0062cc;  /* Изменение цвета фона при активации */
    box-shadow: 0px 0px 10px 0px #54adff;  /* Добавление тени при активации */
}
