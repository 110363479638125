@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrati';
  src: url('./fonts/Montserrat-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

*, *:before, *:after {
  box-sizing: border-box;
}

.Dashboard {
  display: flex;
  height: calc(100vh - 20px);
  flex-direction: column;
  /*width: calc(100% - 10px);*/
  width: 100%;
  min-width: fit-content;
  font-family: 'Montserrat', sans-serif;
  padding: 20px;
}

.upper-bar {
  height: 50px;
  display: flex;
  margin-bottom: 10px;
  background-color: #282937;
  border-radius: 10px;
  box-shadow: 0px 0px 3px 0px rgb(30, 30, 30);
  overflow: hidden;
  border: none;
  justify-content: center;
  flex-shrink: 0;
}

.buttons-bar {
  display: flex;
  align-items: center;
}

.button-rout-left, .button-rout, .button-abandon-account, .account-dropdown-item {
  font-size: 15px;
  height: 100%;
  font-family: 'Montserrat', sans-serif;
  border: none;
  background-color: #282937;
  color: #54adff;
  padding: 10px;
}

.button-rout-left, .button-rout {
  margin-left: 5px;
  margin-right: 5px;
  color: #f0f0f0;
  cursor: not-allowed;
}

.button-rout-left{
  display: flex;
  border-radius: 10px;
  margin-left: 17px;
  height: 70%;
  border: none;
  background-color: #54adff;
  justify-content: center;
  align-items: center;
  padding-top: 0;
  padding-bottom: 0;
  /*box-shadow: inset 0px 0px 5px 0px rgb(30, 30, 30);*/
}

.account-section {
  display: flex;
  margin-left: auto;
  overflow: hidden;
}

.button-abandon-account {
  margin-left: auto;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  padding-right: 40px;
  color: #54adff;
  font-size: 18px;
  text-transform: uppercase;
}

.account-dropdown-menu {
  overflow: hidden;
  width: 0;
  transition: width 0.5s ease-in-out, transform 0.5s ease-in-out;
  white-space: nowrap;
  border: none;
}

.show-dropdown {
  width: 70px; /* Замените на ширину выпадающего списка */
  border: none;
}

.button-abandon-account {
  transition: transform 0.5s ease-in-out;
  cursor: pointer;
}

.move-left {
  transform: translateX(0px); /* Замените на ширину выпадающего списка */
}

.account-dropdown-item{
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #f0f0f0;
  /*color: #090c1b;*/
  /*box-shadow: inset 0px 0px 5px 0px rgba(0, 0, 0, 0.3);*/
  color: #ff0000;
  /*font-weight: bold;*/
  cursor: pointer;
}

h1, h2 {
  max-width: 100%; /* Установка максимальной ширины для заголовков */
  margin: 0; /* Удаление внешних отступов */
  text-align: left; /* Центрирование текста */
}

h1, h2, .sidebar {
  /* Общая ширина элементов (может потребоваться дополнительная настройка) */
  width: 200px;
  /* Выравнивание правых границ */
  margin-right: 10px;
  border-top-left-radius: 10px;
}

.header {
  max-width: 300px; /* Установка максимальной ширины для заголовков */
  margin: 0 auto; /* Центрирование заголовков по горизонтали */
  margin-left: 2.5px;
}

.topLabel {
  margin-left: 7px;
  display: flex;
  align-items: center;  /* Выравнивание дочерних элементов по вертикальной оси */
  justify-content: space-between; /* Распределение дочерних элементов по горизонтальной оси */
}

h1 {
  font-size: 24px;
  text-align: left;
  width: 250px;
  padding-left: 0;
  padding-bottom: 0;
  margin-left: 0;
  margin-top: 0px; /* Убираем верхний отступ */
  margin-right: 0px;
  margin-bottom: 0; /* Убираем нижний отступ */
  padding: 5px; /* Добавляем отступ вокруг текста */
  background-color: #282937; /* Задаем цвет фона */
  color: #54adff;
  /*border-top-right-radius: 10px;*/ /* Скругление верхнего правого угла */
}

h2 {
  font-size: 16px;
  text-align: left;
  /*width: 20vh;*/
  margin-top: -10px;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  padding: 5px;
  color: #54adff; /* Цвет текста заголовка */
  background: #282937; /* Цвет фона заголовка */
}

h3 {
  text-align: center;
  margin: 10px 10px 5px 10px;
  padding: 5px; /* Добавляем отступ вокруг текста */
  border-radius: 7px;
  /*background-color: #090c1b;*/
  color: #090c1b;
  /*background-color: #ffffff;*/
  font-size: 28px;
  text-transform: uppercase;
  box-shadow: inset 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
}

.pre-selected-view {
  margin-top: 0;
  margin-bottom: 0px;
  margin-left: 0px;
  padding: 5px;
  cursor: default;
  text-align: center; /* Центрирование текста внутри h4 */
  /*flex-grow: 1;*/ /* h4 будет занимать свободное пространство */
}

.voke {
  display: flex;
  flex-direction: column;
  /*height: fit-content;*/
  max-height: calc(100% - 65px);
  height: calc(100% - 80px);
  margin: 7px;
  margin-bottom: 0;
  background-color: #f1f1f1;
  border-radius: 10px;
  overflow: hidden;
  /*padding-bottom: 15vh;*/
}

.sidebar {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: fit-content;
  background-color: #282937;
  padding: 10px;
  max-height: 100%;
  margin-bottom: 0px;
  margin-right: 0;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.5);
  /*padding-bottom: 0px;*/
  z-index: 2;
}

.tempLogo svg {
  width: auto;
  max-width: 100%; /* Максимальная ширина */
  height: 46px; /* Пропорциональное изменение высоты */
  margin-top: 3px;
}

.search-container {
  margin-top: 10px;
  margin-left: 10px;
  margin-bottom: 0px;
  width: 300px;
}

.search-container input[type="text"] {
  font-family: 'Montserrat', sans-serif; /* Установка шрифта для текстового поля */
  padding: 8px; /* Добавление внутренних отступов для текстового поля */
  border-radius: 10px; /* Закругление углов */
  width: 100%; /* Заполнение всей доступной ширины */
  /*box-sizing: border-box;*/ /* Учет границы и отступов внутри ширины */
  background-color: #ffffff;
  color: #090c1b;
  border: none;
  box-shadow: inset 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
  /*border: 3px solid #282937;*/
}

.search-container input[type="text"]::placeholder {
  color: #8c8c8c; /* Цвет текста "Поиск" */
}

/* Опциональный стиль для фокуса текстового поля */
.search-container input[type="text"]:focus {
  outline: none; /* Убираем стандартную обводку при фокусе */
  border: 2px solid #54adff; /* Устанавливаем рамку при фокусе */
}

.object-list {
  color: #393939; /* Изменяем цвет текста */
  background-color: #ffffff;
  margin-top: 5px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 12px;
  border-radius: 7px;
  width: 300px;
  height: 100%;
  box-shadow: inset 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
  max-height: calc(100% - 10px); /* Ограничение высоты списка до 10 пикселей от нижней границы экрана */
  overflow-y: auto; /* Добавление возможности прокрутки */
  scrollbar-gutter: stable;
}

.object-list::-webkit-scrollbar {
  margin: 10px 0px;
  width: 9px; /* Ширина полосы прокрутки */
}

.object-list::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0); /* Цвет фона полосы прокрутки */
}

.object-list::-webkit-scrollbar-thumb {
  background: rgba(40, 41, 55, 0.1); /* Цвет полосы прокрутки */
  border-radius: 5px; /* Скругление углов полосы прокрутки */
}

.object-list::-webkit-scrollbar-thumb:hover {
  background: rgba(40, 41, 55, 0.25); /* Цвет полосы прокрутки при наведении */
}

.object-list p {
  margin: 5px 10px;
  cursor: pointer;
  max-height: 100vh;
}

.object-item p {
  align-items: start;
  margin-right: 10px;
  transition: transform 0.2s ease;
}

.object-item {
  height: 35px;
  display: flex;
  align-items: center;
  padding: 0px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  /*border-radius: 10px;*/
}

.object-item:hover:not(.selected) {
  background-color: #54adff;
  /*border: 1px solid #282937;*/
}

.object-item:hover:not(.selected) p {
  transform: translateX(15px); /* Сдвигаем текст вправо на 15px */
  color: #393939;
}

.selected {
  padding-left: 15px;
  color: #393939;
  background-color: #54adff;
  /*border-radius: 10px;*/
  /*border: 1px solid #282937;*/
}

.object-image {
  width: 32px;
  height: 32px;
}

.main-container {
  border-top-left-radius: 10px; /* Скругление верхнего левого угла */
  /*overflow: visible;*/
  height: 100%;
  max-height: calc(100% - 40px);
  min-width: fit-content;
  max-width: 100%;
  /*margin-left: 10px;*/
  display: flex;
  flex-direction: row;
  /*overflow: auto;*/
  /*flex: 1;*/
  /*background-color: #282937;*/
  background-color: #f0f0f0;
  border-bottom-left-radius: 10px;
  /*margin: 20px;*/
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.5);
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  position: relative;
}

.main-content {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 10px;
  /*max-width: 80vh;*/
  background-color: #f0f0f0;
  align-items: center; /* Центрирование содержимого */
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  max-height: 100%;
  height: 100%;
  overflow: hidden;
  /*overflow-y: auto;*/
  z-index: 1;
}

.selected-object {
  display: flex;
  flex-direction: column;
  margin-top: 3px;
  color: #cccccc;
  /*border: 2.5px solid #54adff;
  border-radius: 15px;*/
  /*background-color: #ffffff;*/
  width: 100%; /* Ширина блока с информацией */
  box-sizing: border-box; /* Учитываем границы и отступы в общих размерах */
  max-height: 100%;
  height: fit-content;
  overflow: hidden;
  /*box-shadow: 0px 0px 5px 0px rgb(0, 0, 0, 0.3);*/
  border-radius: 15px;
  /*padding-bottom: 10px;*/
}

.picker {
  height: 63px;
  display: flex;
  flex-direction: row;
  /*background-color: #4CAF50;*/
  justify-content: left;
  align-items: center;
}

.date-time-picker-from input[type="datetime-local"],
.date-time-picker-to input[type="datetime-local"] {
  font-size: 18px;
  font-family: 'Montserrat', sans-serif;
  height: 50%;
  padding-left: 10px;
  border: none;
  border-radius: 5px;
  max-width: 200px;
  /*max-width: 100%;*/ /* Занимает все доступное пространство в контейнере */
  margin-top: 5px;
  margin-left: 7px;
  margin-bottom: 5px;
  margin-right: 5px;
  box-shadow: inset 0px 0px 5px 0px rgb(0, 0, 0, 0.3);
  transition: border 0.2s ease-in-out;
  outline: none;
  cursor: text;
}

.date-time-picker-from input[type="datetime-local"]:focus,
.date-time-picker-to input[type="datetime-local"]:focus {
  border: 0px solid #54adff; /* Синяя граница при фокусе */
}

.date-time-picker-from,
.date-time-picker-to{
  display: flex;
  height: 100%;
  align-items: center;
}

.date-time-picker-from,
.date-time-picker-to{
  display: flex;
  height: 100%;
  align-items: center;
}

.find_button {
  margin: 5px;
  padding: 0px 4px 0px 4px;
  height: 50%;
  width: 100px;
  cursor: pointer;
  /*min-width: fit-content;*/
  background-color: #54adff;
  border: 0px;
  box-shadow: 0px 0px 5px 0px rgb(0, 0, 0, 0.3); /* Тень сверху и слева */
  border-radius: 5px;
  font-family: 'Montserrati', sans-serif;
  font-size: 14px;
  transition: background-color 0.2s ease;
  outline: none;
}

.find_button:hover {
  background-color: #007bff;
}

.find_button:active {
  background-color: #0062cc;  /* Изменение цвета фона при активации */
  box-shadow: 0px 0px 10px 0px #54adff;  /* Добавление тени при активации */
}

.find_button_view {
  margin: 2px;
}

.pre-selected {
  display: flex;
  margin-left: 5px;
  border: 0px;
  /*min-width: fit-content;*/
  width: 100%;
  height: 50%;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: inset 0px 0px 5px 0px rgb(0, 0, 0, 0.3);
  align-content: center;
  overflow: hidden;  /* Скрываем текст, выходящий за границы */
  text-overflow: ellipsis;  /* Добавляем многоточие, если текст не помещается */
  white-space: nowrap;  /* Убираем перенос текста на новую строку */
  color: #cccccc;
}

.report-view {
  flex-grow: 1;
  height: fit-content;
  max-height: calc(100% - 10px);
  max-width: 100%;
  font-size: 12px;
  padding: 10px;
  margin: 10px;
  /*height: calc(100% - 20px);*/
  overflow-y: auto;
  /*scrollbar-gutter: stable;*/
  box-shadow: inset 0px 0px 5px 0px rgb(0, 0, 0, 0.3);
  border-radius: 7px;
  /*background-color: #ffffff;*/
}

.report-view::-webkit-scrollbar {
  margin: 10px 0px;
  width: 9px; /* Ширина полосы прокрутки */
}

.report-view::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0); /* Цвет фона полосы прокрутки */
}

.report-view::-webkit-scrollbar-thumb {
  background: rgba(40, 41, 55, 0.1); /* Цвет полосы прокрутки */
  border-radius: 5px; /* Скругление углов полосы прокрутки */
}

.report-view::-webkit-scrollbar-thumb:hover {
  background: rgba(40, 41, 55, 0.25); /* Цвет полосы прокрутки при наведении */
}

.report-view p {
  text-align: center;
  font-size: 20px;
  color: #090c1b;
}

.download-report {
  margin-top: 7px;
  height: 60px;
  width: calc(100% - 20px);
  cursor: pointer;
  /*min-width: fit-content;*/
  background-color: #54adff;
  border: 0px;
  box-shadow: 0px 0px 5px 0px rgb(0, 0, 0, 0.3); /* Тень сверху и слева */
  border-radius: 7px;
  font-family: 'Montserrati', sans-serif;
  font-size: 20px;
  transition: background-color 0.2s ease;
  outline: none;
}

.download-report:hover {
  background-color: #007bff;
}

.download-report:active {
  background-color: #0062cc;  /* Изменение цвета фона при активации */
  box-shadow: 0px 0px 10px 0px #54adff;  /* Добавление тени при активации */
}

.loading-animation {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 210px;
  height: 148px;
  position: relative;
  overflow: hidden;
  margin-left: calc(50% - 105px);
}

.svg-background, .svg-waves, .svg-car {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.svg-waves {
  animation: fade-waves 4s linear infinite; /* Непрерывное моргание и появление/исчезновение */
}

@keyframes fade-waves {
  0%, 10%, 80%, 90% { opacity: 1; }
  20%, 70% { opacity: 0; } /* Полное исчезновение волны на короткий период времени */
  30%, 60% { opacity: 0.5; } /* Частичное исчезновение для создания эффекта плавности */
  40%, 50% { opacity: 1; } /* Возвращение к полной видимости */
}

.svg-car {
  animation: car-move 4s ease-in-out infinite; /* Перемещение машины туда и обратно */
}

@keyframes car-move {
  /*0%, 100% { transform: translate(-50%, -50%); } !* Машина начинает и заканчивает в центре *!
  25% { transform: translate(-150%, -50%); } !* Машина уезжает влево *!
  50% { transform: translate(150%, -50%); } !* Машина находится справа (вне зоны видимости) *!
  75% { transform: translate(-50%, -50%); } !* Машина возвращается в центр *!*/

  0% {
    transform: translate(-50%, -50%); /*!* Начальная позиция *!*/
  }
  25% {
    transform: translate(-150%, -50%); /*!* Машина уезжает влево *!*/
  }
  50% {
    transform: translate(-200%, -100%) rotate(-45deg); /*!* Машина поворачивает налево и уезжает *!*/
  }
  75% {
    transform: translate(-50%, -50%) rotate(0deg); /*!* Машина возвращается в центр из верхнего левого угла *!*/
  }
  100% {
    transform: translate(-50%, -50%);  /*Конечная позиция*/
  }

  /*0% {
    transform: translate(-50%, -50%); !* Начальная позиция *!
  }
  25% {
    transform: translate(-150%, -50%); !* Машина уезжает влево *!
  }
  50% {
    transform: translate(-200%, -100%) rotate(-90deg); !* Машина поворачивает налево и уезжает ближе к центру *!
  }
  75% {
    transform: translate(-50%, -50%) rotate(0deg); !* Машина возвращается в центр из верхнего левого угла *!
    animation-timing-function: cubic-bezier(0.2, 0, 0.8, 1); !* Ускорение после 75% *!
  }
  100% {
    transform: translate(-50%, -50%); !* Конечная позиция *!
  }*/
}


